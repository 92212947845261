import { useState, useEffect } from "react";
import TicketsGrid from "../components/TicketsGrid";
import Button from "@mui/material/Button";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useNavigate,useSearchParams } from "react-router-dom";
import { serverURL } from "../helpers/Helpers";


const OrderDetails = ({orderId}) => {

  const [order, setOrder] = useState(null);
  const navigate = useNavigate();
  
  const [searchParams] = useSearchParams();


  const getOrder = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(
        serverURL + "/getOrder?orderId=" + id,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "OK") {
        setOrder(data);
      } else {
        setOrder(null);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getOrder(  searchParams.get("orderId")    );
  }, [searchParams])
  


  return (
    <div className="container">
      <div style={{display:"flex", flexDirection: "row"}}>
        <h1 style={{flex:1}}>Order details</h1>
        <Button variant="contained" startIcon={<ArrowBackIos />} onClick={() => navigate("/tickets")}>
            All tickets
        </Button>
      </div>
      { order == null ? <div>Loading...</div> : 
      <div>
        <div>Date: <h4>{order.date}</h4></div>
        <div>PAYPAL status: {order.status}</div>
        <div>PAYPAL status date: {order.statusDate}</div>
        <TicketsGrid tickets={order.order.tickets} hideOrder={true}></TicketsGrid>
      </div>}
    </div>
  );
};

export default OrderDetails;
