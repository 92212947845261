import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { DateToDateString } from "../helpers/Helpers";



export default function TicketsGrid({
      tickets,
      linkToDetails = false,
      hideStatus = false,
      hideOrder = false,
      showSearch = true,
    }) 
{
  const navigate = useNavigate();

  const columns = [
    {
      field: "name",
      headerName: "First name",
      width: 150,
    },
    {
      field: "secondName",
      headerName: "Last name",
      width: 150,
    },
    {
      field: "fromDate",
      headerName: "Start date",
      width: 100,
      sortable: true,
      valueFormatter : (params) => {
        return (DateToDateString(new Date(params.value)));
      }
    },
    {
      field: "expireDate",
      headerName: "End date",
      width: 100,
      sortable: true,
      valueFormatter : (params) => {
        return (DateToDateString(new Date(params.value)));
      }
    },
    {
      field: "ticketType",
      headerName: "Days",
      type: "number",
      width: 70,
    },
    {
      field: "country",
      headerName: "Country",
      width: 110,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      hide: hideStatus,
    },

    {
      field: "orderId",
      headerName: "Order",
      width: 50,
      hide: hideOrder,
      renderCell: (params) => (
        <a href={`/orderdetails?orderId=${params.value}`}>Order</a>
      ),
    },
  ];
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );

  return (
      <DataGrid
        rows={tickets}
        columns={columns}
        autoHeight={true}
        pageSize={20}
        rowsPerPageOptions={[10, 20, 50, 100]}
        components={ showSearch ? { Toolbar: CustomToolbar } : {}}
        componentsProps={ {
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        getRowClassName={(params) => `ticketsList${params.row.status}`}
        onRowDoubleClick={(cell) => {
          if (linkToDetails) {
            navigate("/orderdetails?orderId=" + cell.row.orderId);
          }
        }}
      />
  );
}
