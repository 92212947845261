import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TicketsGrid from "../components/TicketsGrid";
import { serverURL } from "../helpers/Helpers";


const OrderConfirmed = () => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();

  const getOrder = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(
        serverURL + "/getOrder?orderId=" + id,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "OK") {
        setOrder(data);
      } else {
        setOrder(null);
        setError("Error getting order!");
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchParams.get("orderId") !== null)
      getOrder(searchParams.get("orderId"));
    else setError("Something went wrong!");
  }, [searchParams]);

  if (error) return <div>{error}</div>;
  else
    return (
      <div style={{marginTop:"10px", borderRadius:"5px"}}>
        <div className="bg-light">
          <div className="container">
            {order && (
              <div className="py-5 text-center">
                <img
                  className="d-block mx-auto mb-4"
                  src="http://polet-ng.si/images/core/logo.png"
                  alt=""
                  width="72"
                  height="57"
                />
                <h2>Flying tickes Confirmation!</h2>

                <p className="lead">
                  Thank you for buying your flying ticket! You will receive it
                  also to your eMail and must show it on request. <br />
                  Flying tickets are not transferable to another person! 
                </p>
                <h4>List of bought tickets:</h4>
                <TicketsGrid tickets={order.order.tickets} hideStatus={true} hideOrder={true} showSearch={false}></TicketsGrid>

              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default OrderConfirmed;
