import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import NewTicket from "./pages/NewTicket.js";
import TicketsList from "./pages/TicketsList.js";
import OrderDetails from "./pages/OrderDetails.js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import OrderConfirmed from "./pages/OrderConfirmed.js";
import { PRODUCTION } from "./helpers/Helpers";
import Context from "./helpers/context";
import { serverURL } from "./helpers/Helpers";
import { useState, useEffect } from "react";
import { CookiesProvider } from "react-cookie";

const paypalInitOptions = {
  "client-id":
    "ARWTusH_kBTF3LGXVGzaASqtOI9Of8WeLdLd1nWNnewLar7i-Y3w_CeP5yEVDTW90bogxktruZYwetU9",
  currency: "EUR",
  components: "buttons,hosted-fields",
};

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const siteId = queryParameters.get("site") ?? "1";

  const [siteDetails, setSiteDetails] = useState(null);

  const getSiteDetails = async (siteId) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(
        serverURL + `/getSiteDetails?site=${siteId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "OK") {
        setSiteDetails(data.siteDetails);
      } else {
        setSiteDetails(null);
      }
      return data.siteDetails;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSiteDetails(siteId);
  }, [siteId]);

  if (siteDetails == null) 
    return (
      <div style={{display:"flex",flexDirection:"row",width:"100vw", position:"absolute", top:"20vh"}}>
        <div style={{flex:2}} />
        
        <div style={{backgroundColor:"aliceblue",padding:"50px",borderRadius:"5px",textAlign:"center",boxShadow:"3px 3px #0005",border:"solid 1px black" }} >
          Please wait!<br />
          Loading thermals for you!
        </div>
        <div style={{flex:2}} />
        
      </div>
    );
  else
    return (
      <CookiesProvider>
        <Context.Provider value={siteDetails}>
          <PayPalScriptProvider options={paypalInitOptions}>
            <BrowserRouter>
              {PRODUCTION === false && (
                <ul className="navbar-nav mr-auto">
                  <li>
                    <Link to={"/"} className="nav-link">
                      {" "}
                      New Ticket{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={"/tickets"} className="nav-link">
                      All Tickets
                    </Link>
                  </li>
                </ul>
              )}
              <Routes>
                <Route path="/" element={<NewTicket />} />
                <Route path="/tickets" element={<TicketsList />} />
                <Route path="/orderdetails" element={<OrderDetails />} />
                <Route path="/orderconfirmed" element={<OrderConfirmed />} />
              </Routes>
            </BrowserRouter>
          </PayPalScriptProvider>
        </Context.Provider>
      </CookiesProvider>
    );
}

export default App;
