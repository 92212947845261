import { useState, useEffect } from "react";
import TicketsListMain from "./TicketsListMain";
import TicketsListLogin from "./TicketsListLogin";
import { useCookies } from "react-cookie";
import {  add } from "date-fns";

const TicketsList = () => {
  const [cookies, setCookie ] = useCookies(['fvUser']);

  const onLogin = (user) => {
    setCookie("fvUser", user , { expires :  add(new Date(), { days : 1})  });
  };

  

  if (cookies.fvUser === undefined)
    return <TicketsListLogin onLogin={onLogin}></TicketsListLogin>;
  else return <TicketsListMain sites={cookies.fvUser.sites}></TicketsListMain>;
};

export default TicketsList;
