import React from "react";
import { useState, useEffect } from "react";
import TicketsGrid from "../components/TicketsGrid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { serverURL } from "../helpers/Helpers";


const TicketsListDetails = ({ site, date, reloadObject }) => {
  const [ticketsList, setTicketsList] = useState(null);
  const [showAllOrders, setShowAllOrders] = useState(false);

  const getOrders = async (site, date, allOrders) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    setTicketsList(null);

    try {
      const response = await fetch(
        serverURL +
          `/getTickets?site=${site}&date=${date}&allOrders=${allOrders}`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "OK") {
        setTicketsList(data.tickets);
      } else {
        setTicketsList([]);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTicketsList([]);
    getOrders(site, date, showAllOrders);
  }, [site, date, showAllOrders,reloadObject]);

  return (
    <div style={{ marginBottom:"10px"}}>
      {ticketsList !== null && (
        <div style={{border:"1px solid gray", borderRadius:"5px"}}>
          <div style={{display:"flex", flexDirection:"row", padding:"5px"}}>
          <div style={{flexGrow:2}}><h4>{site}</h4></div>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllOrders}
                onChange={(e) => setShowAllOrders(e.target.checked)}
              />
            }
            label="Show uncofirmed"
            labelPlacement="start"
          />
          </div>
          <TicketsGrid tickets={ticketsList} linkToDetails={true}></TicketsGrid>
        </div>
      )}
    </div>
  );
};

export default TicketsListDetails;
