import React from "react";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { format, sub, add } from "date-fns";
import TicketsListDetails from "./TicketsListDetails";
import { useCookies } from "react-cookie";

const TicketsListMain = ({ sites }) => {
  const [reloadObject, setReloadObject] = useState(0);
  const [filterDate, setFilterDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [cookies, setCookie, removeCookie] = useCookies(["fvUser"]);

  return (
      <div className="panelBackground">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={{ flexGrow: 1 }}>
            <h1>Ticketing system - Administration</h1>
          </span>
          <Button
            size="small"
            variant="contained"
            endIcon={<ExitToAppIcon />}
            onClick={() => {
              removeCookie("fvUser");
            }}
          >
            Logout
          </Button>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div>
          <div className="col-sm-4" style={{ display: "contents" }}>
            <h5>
              Date: <span className="text-muted"></span>
            </h5>
            <div
              style={{
                display: "flex",
                flex: "0 0 100%",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <IconButton
                onClick={() => {
                  setFilterDate(
                    format(sub(new Date(filterDate), { days: 1 }), "yyyy-MM-dd")
                  );
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <input
                type="date"
                id="date"
                className={`form-control `}
                style={{
                  minWidth: "150px",
                  maxWidth: "150px",
                  marginRight: "2px",
                }}
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
              <div className="invalid-feedback">Please enter a valid date.</div>
              <IconButton
                onClick={() => {
                  setFilterDate(
                    format(add(new Date(filterDate), { days: 1 }), "yyyy-MM-dd")
                  );
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setReloadObject(Math.random());
                }}
              >
                <RefreshIcon />
              </IconButton>
            </div>
          </div>
          {sites.split(",").map((x) => (
            <TicketsListDetails
              key={x}
              site={x}
              date={filterDate}
              reloadObject={reloadObject}
            ></TicketsListDetails>
          ))}
        </div>
      </div>
  );
};

export default TicketsListMain;
