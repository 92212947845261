const DateToDateString = (date) => {
  const days = date.getDate().toString().padStart(2, "0");
  const months = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${days}/${months}/${date.getFullYear()}`;
};
const DateToDateTimeString = (date) =>
  `${DateToDateString()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  const PRODUCTION = true;
  const serverURL = PRODUCTION ? `https://flyingtickets-server.herokuapp.com` : `http://localhost:5000` ;

  

export { DateToDateString, DateToDateTimeString, serverURL, PRODUCTION };
