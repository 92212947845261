import { width } from "@mui/system";
import { useState } from "react";
import { serverURL } from "../helpers/Helpers";

const TicketsListLogin = ({ onLogin }) => {
  const [adminName, setAdminName] = useState("administrator");

  const checkUser = async (username) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(
        serverURL + `/login?username=${username}`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "OK") {
        onLogin(data.userDetails);
      } else {
        alert("Invalid username!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <span style={{ width: "100%", textAlign: "center" }}>
        <h1>Administration login</h1>
      </span>
      <div style={{ position:"absolute", top:"20vh", left:"0",width:"100%", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth:"800px",
            width:"500px",
            background: "aliceblue",
            padding: "40px",
            borderRadius: "5px"
          }}
        >
          <label htmlFor="userName" className="form-label">
            Username:
          </label>
          <input
            type="text"
            className="form-control"
            id="userName"
            value={adminName}
            onChange={(e) => setAdminName(e.target.value)}
            placeholder="Enter username"
            required
          />
          <div className="invalid-feedback">Invalid username</div>
          <br></br>
          <button
            className="w-100 btn btn-primary btn-lg addPilotTicketBtn"
            onClick={() => checkUser(adminName)}
          >
            Login
          </button>
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

export default TicketsListLogin;
